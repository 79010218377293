import dayjs from 'dayjs'

// 加载中文语言包
import 'dayjs/locale/zh-cn'

import relativeTime from 'dayjs/plugin/relativeTime'

// 配置使用处理相对时间的插件
dayjs.extend(relativeTime)

// dayjs 默认语言是英文，我们这里配置为中文
dayjs.locale('zh-cn') // 全局使用

export function timeFormat (value) {
  value = value + ''
  value = value.replace(/-/g, '/')
  let currentTime = new Date().getTime()
  let postedTime = new Date(value).getTime()
  // 刚刚（1分钟内）
  if (currentTime - postedTime <= 60000) {
    return '刚刚'
  }
  // 1分钟前（1-3）
  if (currentTime - postedTime <= 60000 * 3) {
    return '1分钟前'
  }
  // 3分钟前（3-5）
  if (currentTime - postedTime <= 60000 * 5) {
    return '3分钟前'
  }
  // 5分钟前（5-10）
  if (currentTime - postedTime <= 60000 * 10) {
    return '5分钟前'
  }
  // 10分钟前（10-15）
  if (currentTime - postedTime <= 60000 * 15) {
    return '10分钟前'
  }
  // 15分钟前（15-20）
  if (currentTime - postedTime <= 60000 * 20) {
    return '15分钟前'
  }
  // 20分钟前（20-30）
  if (currentTime - postedTime <= 60000 * 30) {
    return '20分钟前'
  }
  // 30分钟前（30-45）
  if (currentTime - postedTime <= 60000 * 45) {
    return '30分钟前'
  }
  // 45分钟前（45-60）
  if (currentTime - postedTime <= 60000 * 60) {
    return '45分钟前'
  }
  // 1小时前，24小时内
  if (currentTime - postedTime <= 60000 * 60 * 24) {
    const hour = (currentTime - postedTime) / 1000 / 60 / 60
    return `${Math.ceil(hour)}小时前`
  }
  // 当年（不显示年份）
  const postedYear = new Date(postedTime).getFullYear()
  const currentYear = new Date().getFullYear()
  if (postedYear === currentYear) {
    return value.substring(value.indexOf('/') + 1, value.lastIndexOf(':'))
  }
  return value
}


