<template>
  <div
    class="userInfoView"
    :class="{ attInfo: attInfo, ellipsis: ellipsis }"
  >
    <AvatarFrame
      @click.native="goAuthorHome"
      class="userInfoView_icon"
      :user-info="userBasicVO"
    ></AvatarFrame>
    <!--    <div class="userInfoView_icon">-->
    <!--      -->
    <!--      <img :src="displayUrl" />-->
    <!--    </div>-->
    <div class="userInfoView_text">
      <p class="userInfoView_username">
        <span v-if="userName === '匿名'">{{ userName }}</span>
        <span v-else-if="admin === 1 || admin === 2" class="admin">{{
            ADMIN_NAME
          }}</span>
        <span v-else>{{ userName }}</span>
        <!--        <span class="dept">{{deptStr}}</span>-->
        <span v-if="showReply">
          <span> 回复 </span>
          <span
          >@
            <span v-if="toSuperAdmin === 0 || toName === '匿名'">{{
                toName
              }}</span>
            <span
              v-else-if="toSuperAdmin === 1 || toSuperAdmin === 2"
              class="admin"
            >{{ ADMIN_NAME }}</span
            >
          </span>
        </span>
        <span v-if="isAuthor" class="isAuthor">作者</span>
      </p>

      <!--      <p class="userInfoView_timeFormat" v-if="time">{{ time }}</p>-->
      <div class="content">
        <slot name="content"></slot>
      </div>
      <div class="rest">
        <!--        <p class="dept" v-if='deptStr'>{{ deptStr }}</p>-->

        <p class="userInfoView_timeFormat" v-if="time">
          <span class="floor" v-if="isFloor && !hideRemove && sortKey === 'time'">{{
              postTotal - index == 1 ? '沙发' : `第${postTotal - index}楼`
            }}</span>
          <span class="public-time">{{ timeFormat(time) }}</span>
          <span v-if="isFloor && !hideRemove" class="reply" @click.stop="reply"
          >回复</span
          >
          <slot name="reply"></slot>
        </p>
        <p class="attCount" v-if="attCount && attCount > 1">
          等{{ attCount }}人
        </p>
        <div class="browse" v-if="showBrowse">
          阅读人次 {{ browseCount > 0 ? browseCount : 0 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import {isURL} from '@/utils/utils'
import {timeFormat} from '@/utils/dayjs'
import {USER_IMAGE, ADMIN_IMAGE, ADMIN_NAME} from '@/config/config'

export default {
  components: {
    AvatarFrame
  },
  props: {
    sortKey: String,
    iconUrl: {
      type: String
    },
    userName: String,
    time: String,
    admin: {
      type: [Number, Boolean]
    },
    isAuthor: {
      type: Boolean,
      default: () => false
    },
    showReply: {
      type: Boolean,
      default: () => false
    },
    toName: {
      type: String
    },
    userBasicVO: {
      type: Object,
      default: () => {
      }
    },
    toSuperAdmin: Number,
    deptStr: String,
    attCount: Number,
    attInfo: Boolean,
    ellipsis: Boolean,
    anonymous: Boolean,
    toAnonymous: Boolean,
    browseCount: Number,
    showBrowse: Boolean,
    index: Number,
    postTotal: Number,
    isFloor: Boolean,
    hideRemove: Boolean
  },
  computed: {
    displayUrl () {
      let isUrl = isURL(this.iconUrl)
      if (isUrl) {
        if (this.admin === 1 || this.admin === 2) {
          return ADMIN_IMAGE
        } else {
          return this.iconUrl
        }
      } else {
        return USER_IMAGE
      }
    },

    // timeFormat() {
    //   const { time } = this
    //   let currentTime = new Date().getTime()
    //   let postedTime = new Date(time).getTime()
    //   if (currentTime - postedTime <= 86400000) {
    //     console.log(currentTime, postedTime)
    //   } else {
    //     return time.substring(time.indexOf('-') + 1, time.lastIndexOf(':'))
    //   }
    // }
  },
  data () {
    return {
      ADMIN_NAME
    }
  },
  methods: {
    timeFormat,
    // 个人中心
    goAuthorHome () {
      if (this.userBasicVO.username === "匿名") {
        return
      }
      if (this.userBasicVO && this.userBasicVO.userId) {
        this.$router.push({
          name: 'userInfoDetail',
          params: {userId: this.userBasicVO.userId}
        })
      }
    },
    reply () {
      this.$emit('reply')
    }
  }
}
</script>

<style lang="scss" scoped>
.isAuthor {
  display: inline-block;
  width: 34px;
  line-height: 18px;
  background: #f6f6f6;
  border-radius: 10px;
  font-size: 11px;
  text-align: center;
  margin-left: 10px;
}

.userInfoView_timeFormat {
  font-size: 13px !important;
}

.reply {
  margin-left: 5px;
  font-size: 10px !important;
  color: #666666;
  line-height: 14px;
}

.attInfo {
  flex-direction: row-reverse;

  .userInfoView_text {
    margin-left: 0;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ellipsis {
  .dept {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60px;
    overflow: hidden;
  }

  .userInfoView_username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60px;
  }
}

.userInfoView {
  display: flex;

  &_icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    position: relative;
    //border-radius: 50%;
    //overflow: hidden;
    //flex-shrink: 0;

    //img {
    //  width: 40px;
    //  height: 40px;
    //  object-fit: cover;
    //}
  }

  &_text {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &_username {
    @include mainText;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 220px;

    .dept {
      margin-left: 5px;
      font-size: 12px;
    }
  }

  .dept {
    margin: 0;
    font-size: 10px;
    color: #000;
  }

  &_timeFormat {
    @include remark;
    font-size: 12px;
    color: #6d7278;
    margin: 0;
    .floor{
      margin-right: 4px;
      font-size: 10px;
      color: #AAAAAA;
    }
    .public-time{
      font-size: 10px;
      color: #AAAAAA;
    }
  }

  .attCount {
    font-size: 12px;
    color: #6d7278;
    margin: 0;
    position: relative;
  }
}
</style>
