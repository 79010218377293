<template>
  <div class="vote" :class="hideVoteInfo?'hideShadow':''">
    <div class="vote_head">
      <span class="title" v-html="title"></span>
      <span class="partternBtn">{{ patternText }}</span>
    </div>
    <div class="vote_progress" v-for="(item, index) in voteList" :key="index" @click.stop="handleCheckVote(item)">
      <van-progress
        :percentage="isMeVoted?item.percentage:99"
        :stroke-width="36"
        :color="item.voteFlag?'rgba(254, 88, 0, 0.1)':'#fff'"
        track-color='#FFF'
        :show-pivot='false'
        :class="item.voteFlag?'progressActive':''"
      />
      <div class="vote_progress_text" :class="isMeVoted?'':'notVote'">
        <div class="text_left">
          <div class="progress_num" :class="item.voteFlag?'active':''">{{item.content}}</div>
          <img src="/images/topicItem/choose.png" v-if="item.voteFlag"/>
        </div>
        <div class="progress_num" :class="item.voteFlag?'active':''" v-if="isMeVoted">{{item.percentage}}%</div>
      </div>
    </div>
    <div class="vote_info" v-if="!hideVoteInfo">
      <p class="vote_info_count">{{voteCount}}人参与了投票</p>
      <p class="time">还有{{ time > 0 ? time : 0 }}天结束</p>
      <van-button v-if="!isMeVoted" color="#FFEEEB" round @click.stop="handlePublicVote">投票</van-button>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  components: {},
  props: {
    title: String,
    voteList: Array,
    pattern: Number,
    voteCount: Number,
    isMeVoted: Boolean,
    voteStartTime: String,
    voteEndTime: String,
    hideVoteInfo: Boolean
  },
  computed: {
    patternText() {
      return this.pattern === 0 ? "单选" : "多选";
    },
    time() {
      return dayjs(this.voteEndTime).diff(dayjs().format(), 'day')
    }
  },
  methods: {
    handleCheckVote(item) {
      if(!this.isMeVoted) {
        this.$emit('click', item)
      } else {
        this.$emit('overviewVoteClick')
      }
    },
    handlePublicVote() {
      this.$emit('vote')
    }
  },
};
</script>

<style lang="scss" scoped>
.hideShadow {
  box-shadow: none !important;
}
.vote {
  box-shadow: 0 0 6px #ffe2dd;
  padding: 5px;

  &_head {
    display: flex;
    align-items: center;
    padding: 5px;

    .title {
      font-size: 16px;
      @include ellipsis(1);
      font-weight: bold;
      margin: 0;
    }
    .partternBtn {
      background-color: $mainColor;
      color: white;
      font-size: 8px;
      height: 16px;
      line-height: 16px;
      margin-left: 8px;
      padding: 0 3px;
    }
  }

  &_progress {
    position: relative;

    .van-progress {
      border-radius: 6px;
      margin-bottom: 5px;
      position: relative;
      border: 1px solid #E0E0E0;

      ::v-deep .van-progress__pivot {
        background-color: transparent !important;
      }
    }
    .progressActive {
      border: 1px solid #FE5800;
    }
    &_text {
      display: flex;
      align-items: center;
      z-index: 10;
      justify-content: space-between;
      padding: 0 20px;
      position: absolute;
      top: 9px;
      width: 85%;
      font-size: 14px;

      .text_left {
        display: flex;

        img {
          width: 12px;
          margin-left: 15px;
        }
      }

      .progress_num {
        right: 30px;
        top: 8px;
        color: $mainTextColor;
      }
      .active {
        color: #FE5800;
      }
    }
    .notVote {
      .progress_num {
        color: #5475A6;
      }
    }
  }

  &_info {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    &_count {
      color: #A8A8A8;
    }
    .van-button {
      width: 60px;
      height: 27px;
      font-size: 12px;

      &__content {
        color: $mainColor;
      }
    }
    .time {
      font-size: 12px;
      margin: 0;
      color: #A8A8A8;
    }
  }
}
</style>
